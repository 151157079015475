<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.senderId === formattedChatData.contact.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.senderId === formattedChatData.contact.id ? formattedChatData.contact.avatar : formattedChatData.profileUserAvatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="(msgData, indexMsg) in msgGrp.messages"
          :key="msgData.msg+String(indexMsg)"
          class="chat-content"
        >
          <p v-if="!msgData.isResponseBot">
            {{ msgData.msg }}
          </p>
          <div v-else>
            <p>
              {{ msgData.msg.text }}
            </p>
            <div>
              <p v-if="msgData.msg.card.length > 0">
                {{ msgData.msg.fulfillmentText }}
              </p>
              <div
                v-for="(msgCard, numero) in msgData.msg.card"
                :key="numero"
              >
                <CardChat
                  :card-data="msgCard"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { BAvatar } from 'bootstrap-vue'
import config from '@/services/config'
import getDisplayChat from '@/utils/customs'
import CardChat from './CardChat.vue'

export default {
  components: {
    BAvatar,
    CardChat,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { baseURL, contextPI } = config
    const pathMultimedia = ref(`${baseURL}${contextPI}/api/v1/multimedia/byUrl?isthumb=true&`)
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.idUsuario,
        avatar: getDisplayChat(props.chatData.contact.display),
      }
      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = props.chatData.miId ? props.chatData.miId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (msg.isResponseBot) {
          const dataJSON = JSON.parse(msg.dataJson)
          if (dataJSON.queryResult && dataJSON.queryResult.fulfillmentMessages) {
            chatMessageSenderId = msg.senderId
            formattedChatLog.push(msgGroup)
            const cardBot = []
            let textBot = ''
            dataJSON.queryResult.fulfillmentMessages.forEach(botMsg => {
              if (botMsg.card) {
                let cards = null
                const button = botMsg.card.buttons[0].text
                cards = botMsg.card
                cards.buttons = button
                cardBot.push(cards)
              } else {
                textBot += ` ${botMsg.text.text}`
              }
            })
            /*     if (cardBot.length === 0) {
              cardBot = null
            } else {
              textBot = null
            } */
            msgGroup = {
              senderId: msg.senderId,
              messages: [{
                msg: {
                  card: cardBot,
                  text: textBot,
                  fulfillmentText: dataJSON.queryResult.fulfillmentText,
                },
                time: msg.time,
                isResponseBot: true,
              }],
            }
          }
        } else if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
            isResponseBot: false,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
              isResponseBot: false,
            }],
          }
        }

        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
      pathMultimedia,
    }
  },
}
</script>

<style>

</style>
