<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          Iniciar Conversación
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                size="36"
                :src="getDisplayChat(activeChat.contact.display)"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant('online')"
                @click.native="shallShowActiveChatContactSidebar=true"
              />
              <h6 class="mb-0">
                {{ activeChat.contact.nombres }} {{ activeChat.contact.apellidos }}
              </h6>
            </div>

            <!-- Contact Actions -->
            <!-- <div class="d-flex align-items-center">
              <feather-icon
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
              <feather-icon
                icon="SearchIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
              />
              <div class="dropdown">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    View Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Mute Notifications
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Block Contact
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Clear Chat
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Report
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div> -->
          </header>
        </div>

        <!-- User Chat Area -->
        <div
          ref="refChatLogPS"
          class="user-chats scroll-area"
          style="overflow-y: auto;"
        >
          <chat-log
            :chat-data="activeChat"
            :profile-user-avatar="profileUserDataMinimal.avatar"
          />
        </div>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Escriba un mensaje..."
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSending"
          >
            Enviar
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeChat? activeChat.idChat : null"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, nextTick, watch,
} from '@vue/composition-api'
import {
  BAvatar, /* BDropdown, BDropdownItem, */ BForm, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import SocketioService from '@/services/socket'
import config from '@/services/config'
import getDisplayChat from '@/utils/customs'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {

    // BSV
    BAvatar,
    /*  BDropdown,
    BDropdownItem, */
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const isSending = ref(false)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      if (scrollEl) {
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])
    const idDirectorio = ref(null)
    const { baseURL, contextPI } = config
    const pathMultimedia = ref(`${baseURL}${contextPI}/api/v1/multimedia/byUrl?isthumb=true&`)
    const fetchChatAndContacts = async () => {
      await store.dispatch('app-chat/fetchChatsAndContacts')
        .then(response => {
          //  chatsContacts.value = response.data.chatsContacts
          //  contacts.value = response.data.contacts
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = response.data.profileUser
          const user = JSON.parse(localStorage.getItem('userData'))
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = {
            // eslint-disable-next-line no-use-before-define
            ...profileUserDataMinimal.value,
            avatar: `${pathMultimedia.value}url=${user.display}`,
            ...user,
          }
          // eslint-disable-next-line no-use-before-define
        })
    }
    const fetchListChats = async () => {
      await store.dispatch('panel/SEARCH_LIST_CHAT', {
        estado: 'ACTIVO',
        limit: 1000,
        page: 1,
      })
        .then(response => {
          chatsContacts.value = response.items
        })
    }
    fetchListChats()
    fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = async contact => {
      // Reset send message input value
      chatInputMessage.value = ''
      await store.dispatch('panel/SEARCH_LIST_MESSAGGE', {
        limit: 1000,
        page: 1,
        query: '',
        sortBy: 'idMensaje%7CASC',
        idChat: contact.idChat,
      }).then(response => {
        const chat = []
        const object = {
          idChat: contact.idChat,
          miId: contact.usuarioEmpresa.idUsuario,
          userId: contact.usuarioTurista,
          unseenMsgs: 1,
          chat,
          contact: contact.usuarioTurista,
        }
        response.items.forEach(element => {
          chat.push({
            message: element.mensaje,
            time: element.fechaEnvio,
            senderId: element.idUsuarioEnvia,
            dataJson: JSON.parse(JSON.stringify(element.dataJson)),
            isResponseBot: element.isResponseBot,
          })
        })

        object.chat = chat
        activeChat.value = object
        nextTick(() => { scrollToBottomInChatLog() })
        // eslint-disable-next-line no-use-before-define
        mqShallShowLeftSidebar.value = false
      })
    }
    const sendMessage = async () => {
      if (!chatInputMessage.value) return
      const user = JSON.parse(localStorage.getItem('userData'))
      const idUsuarioLoggeado = user.id
      isSending.value = true
      const postMessage = {
        idChat: activeChat.value.idChat,
        idUsuarioRecibe: activeChat.value.contact.idUsuario,
        idUsuarioEnvia: idUsuarioLoggeado,
        mensaje: chatInputMessage.value,
      }

      await store.dispatch('panel/CREATE_MESSAGE', postMessage)
        .then(async response => {
          await store.dispatch('panel/UPDATE_MESSAGE', {
            etapa: 'envio',
            idMensaje: response.result.idMensaje,
          }).then(() => {
          })
          const { result } = response

          activeChat.value.chat.push({
            message: result.mensaje,
            time: result.fechaRegistro,
            senderId: idUsuarioLoggeado,
          })

          SocketioService.socket.emit('send_message', {
            objeto: result,
            content: chatInputMessage.value,
            receiverChatID: result.idUsuarioRecibe.toString(),
          })
          chatInputMessage.value = ''

          // Scroll to bottom
          nextTick(() => { scrollToBottomInChatLog() })
        }).finally(() => {
          isSending.value = false
        })
      /*  const { newMessageData, chat } = response.data

          // ? If it's not undefined => New chat is created (Contact is not in list of chats)
          if (chat !== undefined) {
            activeChat.value = { chat, contact: activeChat.value.contact }
            chatsContacts.value.push({
              ...activeChat.value.contact,
              chat: {
                id: chat.id,
                lastMessage: newMessageData,
                unseenMsgs: 0,
              },
            })
          } else {
            // Add message to log
            activeChat.value.chat.chat.push(newMessageData)
          }

          // Reset send message input value
          chatInputMessage.value = ''

          // Set Last Message for active contact
          const contact = chatsContacts.value.find(c => c.id === activeChat.value.contact.id)
          contact.chat.lastMessage = newMessageData */
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 1000,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser')
        .then(response => {
          profileUserData.value = response.data
          shallShowUserProfileSidebar.value = true
        })
    }
    watch(chatInputMessage, () => {
      SocketioService.socket.emit('writing', {
        receiverChatID: '2',
      })
    })

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }
    const listenMessagesSocket = () => {
      SocketioService.socket.on('receive_message', payload => {
        /* json parse */
        const messageReceived = JSON.parse(payload.objeto)
        if (activeChat.value.contact !== null && activeChat.value.idChat === messageReceived.idChat) {
          activeChat.value.chat.push({
            message: messageReceived.mensaje,
            time: messageReceived.fechaRegistro,
            senderId: messageReceived.idUsuarioEnvia,
          })

          nextTick(() => { scrollToBottomInChatLog() })
        }
      })
    }

    listenMessagesSocket()
    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,
      listenMessagesSocket,
      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,
      isSending,
      // UI
      perfectScrollbarSettings,
      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
      /* CHAT */
      idDirectorio,
      pathMultimedia,
      getDisplayChat,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

</style>
