<template>
  <b-row class="match-height">
    <b-col
      md="12"
      lg="12"
    >
      <div class="card">
        <img
          :src="cardData.imageUri"
          alt="Card image cap"
          class="card-img-top"
          style="max-height: 200px;"
        ><!----><div class="card-body">
          <h4 class="card-title">
            {{ cardData.title }}
          </h4><!----><p
            class="card-text"
            style="color: grey;"
          >
            {{ cardData.subtitle }}
          </p><button
            type="button"
            class="btn btn-outline-primary"
          >
            {{ cardData.buttons }}
          </button>
        </div><!----><!---->
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
/*   BCard, BCardText, BButton, */
  BCol, BRow,
  /*
  BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, */
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    /*    BCard,
    BCardText,
    BButton, */
    BRow,
    /*     BCardBody,
    BCardTitle,
    BCardSubTitle,
   */
    BCol,
    /*     BLink,
    BImg, */
  },
  directives: {
    Ripple,
  },
  props: {
    cardData: {
      type: Object,
      required: true,
    },

  },
}
</script>
