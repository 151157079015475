import config from '@/services/config'

const { baseURL, contextPI } = config
const pathMultimedia = `${baseURL}${contextPI}/api/v1/multimedia/byUrl?isthumb=true&`
export default function getDisplayChat(display) {
  if (display?.includes('images/display-turista')) {
    return `${pathMultimedia}url=${display}`
  }
  return display
}
